// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-browse-js": () => import("./../../../src/pages/browse.js" /* webpackChunkName: "component---src-pages-browse-js" */),
  "component---src-pages-cancelled-js": () => import("./../../../src/pages/cancelled.js" /* webpackChunkName: "component---src-pages-cancelled-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-make-js": () => import("./../../../src/pages/make.js" /* webpackChunkName: "component---src-pages-make-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

